/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
  @apply bg-green-500 hover:bg-green-800 text-white py-2 px-4 rounded transition-all rounded-xl;
}

.btn-secondary {
  @apply bg-gray-200 hover:bg-gray-300 text-black py-2 px-4 rounded transition-all rounded-xl;
}

/* styles.css */
.input-style {
  background: #fff;
  color: #303030;
  font-size: 14px;
  height: 50px;
  padding-left: 20px;
  border: 2px solid #e4ecf2;
}